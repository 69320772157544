<template>
    <div v-if="task.taskType.alias === 'QUIZ_IMAGE'">
        <img
            class="option-image"
            :class="{
                'correct-answer': isCorrect,
                'wrong-answer': !isCorrect,
                'visible': isAnswerVisible }"
            :src="`${answer.imageUrl}`"
            alt="option image"
        >
    </div>
    <div
        v-else
        class="option-text"
        :class="{
            'correct-answer': isCorrect,
            'wrong-answer': !isCorrect,
            'visible': isAnswerVisible }"
    >
        {{ answer.content }}
    </div>
</template>

<script>
    export default {
        name: 'Answer',
        props: {
            answer: {
                type: Object,
                required: true
            },
            isCorrect: {
                type: Boolean,
                required: true
            },
            isAnswerVisible: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            }
        }
    }
</script>

<style lang="scss" scoped>
.option-image {
    width: 50%;
    border: 3px solid var(--white);
    padding: 5px;
    cursor: pointer;
}

.option-image.correct-answer.visible {
    border: 3px solid var(--correct);
}

.option-image.wrong-answer.visible {
    border: 3px solid var(--error);
}

.option-text {
    border-radius: 20px;
    border: 1px solid var(--white);
    padding: 9px 18px;
    margin: 0 18px;
    margin-bottom: 12px;
    text-align: center;
    cursor: pointer;
    font-size: 0.8em;
    background: rgb(0 0 0 / 30%);
    color: var(--white);
    transition: all 0.2s ease-in-out;
}

.option-text:hover {
    background: var(--secondary-color);
}

.option-text:active {
    color: var(--white);
}

.option-text.correct-answer.visible {
    border: 1px solid var(--white);
    background-color: var(--correct);
    color: var(--white);
}

.option-text.wrong-answer.visible {
    border: 1px solid var(--white);
    background: var(--error);
    color: var(--white);
}

</style>
