<template>
    <div v-if="task && task.taskType" class="task">
        <task-top-bar />
        <b-container
            v-if="showInstructions"
            class="instructions-container px-3"
        >
            <b-row>
                <b-col class="margin-elements">
                    <span class="points-text-1 mr-2"><font-awesome-icon :icon="[ 'fas', 'plus-circle' ]" /></span><span
                        class="points-text-2"
                    >{{ task.points }}</span><span class="ml-2 points-text-1">{{ $t('taskRoute.points') }}</span>
                </b-col>
                <b-col class="intro-box" lg="6">
                    <task-intro :task-index="taskIndex" />
                    <span v-if="hideArGame" class="mobile-warning text-warning">
                        {{ $t('taskRoute.arMobileWarning') }}
                    </span>
                    <button
                        v-if="!isVRGame"
                        :class="{'disabled': hideArGame}"
                        :disabled="hideArGame"
                        class="start-button"
                        @click="start()"
                    >
                        {{ taskButtonText }}
                    </button>
                    <button
                        v-else
                        class="start-button"
                        @click="openVrIntroModal()"
                    >
                        {{ taskButtonText }}
                    </button>
                </b-col>
                <b-col />
            </b-row>
        </b-container>
        <div v-else :class="{'full-height': isGame}" class="game-wrapper">
            <game v-if="isGame" />
            <action-read v-if="task.taskType.alias === 'ACTION_READ'" />
            <quiz v-if="isQuiz && !isDailyQuiz" />
            <button
                v-if="isCheatMode"
                class="earn-max"
                @click="submitMaxPoints"
            > {{ $t('taskRoute.earnMaxPoints') }}
            </button>
        </div>
    </div>

</template>

<script>
    import taskUtil from '@/utils/task-util'
    import Quiz from '@/components/Quiz'
    import Game from '@/components/games/Game'
    import ActionRead from '@/components/ActionRead'
    import TaskTopBar from '@/components/TaskTopBar'
    import TaskIntro from '@/components/TaskIntro'
    import emitter from '@/utils/emitter'

    export default {
        name: 'TaskRoute',
        components: {
            Quiz,
            Game,
            ActionRead,
            TaskTopBar,
            TaskIntro
        },
        data() {
            return {
                showInstructions: true,
                hasCamera: false
            }
        },
        computed: {
            hideArGame() {
                return (this.isArGame && (this.deviceType !== 'mobile' || !this.hasCamera)) && this.config.environment !== 'development'
            },
            deviceType() {
                return this.$store.getters.deviceType
            },
            isQuiz() {
                return this.task && this.task.taskType && this.task.taskType.alias.startsWith('QUIZ')
            },
            isDailyQuiz() {
                return this.task && this.task.taskType && this.task.taskType.alias === 'QUIZ_DAILY'
            },
            isGame() {
                if (!this.task || !this.task.taskType) {
                    return false
                }
                return this.task.taskType.alias.startsWith('GAME')
            },
            isArGame() {
                if (!this.task || !this.task.taskType) {
                    return false
                }
                return this.task.taskType.alias.endsWith('_AR')
            },
            isVRGame() {
                if (!this.task || !this.task.taskType) {
                    return false
                }
                return this.task.taskType.alias.endsWith('_VR')
            },
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            taskIndex() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTaskIndex(missionId, taskId)
            },
            isCheatMode() {
                return this.$store.getters.isCheatMode
            },
            taskButtonText() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_TASK_BUTTON'
                }
                return this.task.multilingualTask[0].config.taskButtonText
            }
        },
        watch: {
            task: {
                handler(newVal) {
                    if (newVal) {
                        this.checkCameraForAr()
                    }
                },
                immediate: true
            }
        },
        beforeDestroy() {
            window.removeEventListener('popstate', function() {
            })
        },
        async created() {
            let missionId = parseInt(this.$route?.params?.missionId)
            let taskId = parseInt(this.$route?.params?.taskId)
            if (missionId && taskId) {
                const isInvalid = await this.$store.dispatch('startTask', { missionId, taskId })
                if (isInvalid) {
                    await this.$router.push({ path: '/missions/' + missionId, replace: true })
                }
            }
        },
        methods: {
            async checkCameraForAr() {
                if (!this.isArGame) {
                    return
                }
                const devices = await navigator.mediaDevices.enumerateDevices()
                let cameraDevices = []
                if (devices && devices.length > 0) {
                    cameraDevices = devices.filter(device => device.kind === 'videoinput')
                }
                if (cameraDevices.length > 0) {
                    this.hasCamera = true
                }
            },
            start() {
                this.showInstructions = false
            },
            openVrIntroModal() {
                emitter.emit('openVrGameIntroModal')
            },
            submitMaxPoints() {
                let maxPoints = this.task.points
                let finished = true
                emitter.emit('killTimer', { taskId: parseInt(this.$route.params.taskId) })
                taskUtil.updateTask(this.mission, this.task, maxPoints, finished, this.$router)
            }
        }
    }
</script>

<style lang="scss" scoped>
.task {
    flex-grow: 1;
    min-height: 100% !important;
}

.game-wrapper {
    padding-top: 2rem;
}

.game-wrapper.full-height {
    padding-top: 0;
    min-height: 100vh;
}

.intro-box {
    background: rgb(0 0 0 / 40%);
    width: 100%;
    padding: 1rem 0;
    margin: 0 auto;
}

.points-text-1 {
    font-weight: 700;
    color: var(--main-content-text);
    font-size: 1.5rem;
    text-align: center;
}

.points-text-2 {
    font-weight: 700;
    color: var(--secondary-color);
    font-size: 2.25rem;
    text-align: center;
}

.start-button {
    margin: 1rem auto;
    min-width: 120px;
    height: 40px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 0;
    background: var(--secondary-color);
    color: var(--white);
    transition: all 0.2s ease-in-out;
    outline: none;
    display: grid;
    place-items: center;
}

.start-button:active {
    transform: scale(0.9);
}

.start-button.disabled {
    background: var(--disabled-color);
    color: var(--disabled-text-color);
}

.start-button.disabled:active {
    transform: none;
}

.earn-max {
    position: absolute;
    bottom: 1%;
    left: 40%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
    .instructions-container {
        margin-top: 2rem;
    }

    .start-button {
        margin-top: 2rem;
        width: 160px;
        height: 45px;
    }

    .earn-max {
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
    }
}

</style>
