import qrSpotScanService from '@/services/qr-spot-scan-service'
export default {
    methods: {
        async scanQrSpot(code, recaptchaAction, page) {
            const reCaptchaResponse = await this.recaptcha(recaptchaAction)
            qrSpotScanService.scanQrSpot({
                page,
                code,
                reCaptchaResponse
            }).then(() => {
                if (page === 'REGISTER') {
                    localStorage.removeItem('scan-code')
                }
            })
        }
    }
}
