<template>
    <b-container fluid>
        <b-row class="pb-5">
            <b-col lg="3" class="d-none d-sm-block margin-lef-right-elements">
                <span class="points-text-1 mr-2">+</span><span class="points-text-2">{{ task.points }}</span><span class="ml-2 points-text-1">{{ $t('actionRead.points') }}</span>
            </b-col>
            <b-col lg="6" class="article-box">
                <div class="mt-4 mb-2 d-none d-sm-block">
                    <span class="article-title">{{ alias }}</span>
                </div>
                <b-container class="reading-container-out">
                    <b-container class="reading-container-in">
                        <b-container v-if="articles[activeItem]" class="text-container-title mt-4">
                            <span>{{ articles[activeItem].title }}</span>
                        </b-container>
                        <b-container class="text-container-content mt-2">
                            <div
                                v-if="articles.length>0"
                                id="readImg"
                                v-dompurify-html="articles[activeItem].content"
                            />
                        </b-container>
                    </b-container>
                </b-container>
                <b-row>
                    <b-col>
                        <button v-if="activeItem >= articles.length - 1 && articles.length > 1" class="mt-5 button" :disabled="activeItem <= 0" @click="activeItem--">{{ $t('actionRead.previous') }}</button>
                    </b-col>
                    <b-col>
                        <button
                            v-if="activeItem <= 0 && activeItem != articles.length - 1"
                            class="mt-5 button"
                            :disabled="activeItem >= articles.length - 1"
                            @click="activeItem++"
                        >{{ $t('actionRead.next') }}</button>
                        <button v-if="activeItem == articles.length - 1" class="mt-5 button" @click="submitMaxPoints"> {{ $t('actionRead.complete') }} </button>
                    </b-col>
                </b-row>
            </b-col>
            <div class="quiz-instructions desktop-only">
                <div class="owl-bg">
                    <owl-svg />
                </div>
                <span class="instructions-text">{{ instructions }}</span>
            </div>
        </b-row>
    </b-container>
</template>

<script>
    import taskUtil from '@/utils/task-util'
    import OwlSvg from '@/components/svg/OwlSVG'
    export default {
        name: 'ActionRead',
        components: {
            OwlSvg
        },
        data() {
            return {
                articles: [],
                activeItem: 0
            }
        },
        computed: {
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            alias() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return this.task.multilingualTask[0].config.alias
            },
            instructions() {
                if (!this.task.multilingualTask || !this.task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_INSTRUCTIONS'
                }
                return this.task.multilingualTask[0].config.instructions
            }
        },
        mounted() {
            this.fetchArticles()
        },
        methods: {
            fetchArticles() {
                this.articles = this.task.articles
            },
            submitMaxPoints() {
                let maxPoints = this.task.points
                let finished = true
                taskUtil.updateTask(this.mission, this.task, maxPoints, finished, this.$router)
            }
        }
    }
</script>

<style lang="scss" scoped>
.reading-container-out {
    height: 500px;
    display: table;
    padding: 5px;
}

.article-box {
    background: rgb(0 0 0 / 30%);
    padding: 25px;
    margin-top: 20px;
}

.reading-container-in {
    background: var(--white);
    border: 5px solid var(--secondary-color);
    display: table-cell;
    text-align: left;
}

.text-container-title {
    font-weight: 700;
    font-size: 25px;
    color: var(--black);
}

.text-container-content {
    overflow: auto;
    height: 350px;
    font-size: 14px;
    color: var(--black);
}

.margin-lef-right-elements {
    margin-top: 35px;
}

.article-text {
    color: var(--white);
    font-size: 18px;
}

.points-text-1 {
    font-weight: 700;
    color: var(--white);
    font-size: 36px;
    text-align: center;
}

.points-text-2 {
    font-weight: 700;
    color: var(--secondary-color);
    font-size: 36px;
    text-align: center;
}

.task-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--white);
}

.article-title {
    font-weight: 700;
    font-size: 14px;
    color: var(--white);
}

.button {
    padding: 8px 25px;
    font-size: 100%;
    border-radius: 20px;
    border: 1px solid var(--white);
    background: rgb(0 0 0 / 30%);
    color: var(--white);
    outline: none;
}

.button:hover {
    color: var(--secondary-color);
    outline: none;
}

.button:active {
    outline: none;
}

.owl-bg {
    margin: 0 auto;
    background: var(--secondary-color);
    padding: 0.5rem;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

@media screen and (min-width: 768px) {
    .quiz-instructions {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: var(--main-content-text);
        font-size: 1.125rem;
        max-width: 300px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 992px) {
    .quiz-instructions {
        display: flex !important;
        gap: 0.25rem;
    }
}
</style>

<style lang="scss">
    #readImg {
        img {
            max-width: 100% !important;
        }
    }

    .ql-align-center {
        text-align: center !important;
    }

    .ql-align-right {
        text-align: right !important;
    }

    .ql-align-justify {
        text-align: justify !important;
    }
</style>
